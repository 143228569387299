import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import {TextContent, GridWrap, SectionTitle, Table, CellCaption, CellTile, CellWrap} from "../components/style"
import Seo from "../components/seo"
import Layout from "../components/layout"

const AboutPage = () => 
  <Layout>
    <Seo title="About" />
    <TextContent>
    I'm a product designer originally from Germany. In my spare time I enjoy creating things with code, paper, wood and clay. I'm interested in working on technology that can help tackle climate change, education and health. 
    </TextContent>
    <SectionTitle>Experience</SectionTitle>
    <Table>
      <tbody>
        <tr><td>2019 — Now</td><td><Link to="/neighborhoods">Facebook Neighborhoods</Link> <span>at</span> Facebook HQ</td><td>Menlo Park, United States</td></tr>
        <tr><td>2018 — 2019</td><td><Link to="/events">Facebook Events</Link> & <Link to="/localapp">Local App</Link> <span>at</span> Facebook HQ</td><td>Menlo Park, United States</td></tr>
        <tr><td>2016 — 2018</td><td><Link to="/workplace">Facebook Workplace</Link> <span>at</span> Facebook</td><td>London, United Kingdom</td></tr>
        <tr><td>2014 — 2016</td><td><Link to="/maps">Google Maps</Link> <span>at</span> Google</td><td>Zurich, Switzerland</td></tr>
        <tr><td>2014 — 2016</td><td><Link to="/watch">Wearables</Link> <span>at</span> Google & Motorola</td><td>Zurich, Switzerland</td></tr>
        <tr><td>2014 — 2016</td><td><Link to="/awv">Adwords for Video</Link> <span>at</span> YouTube</td><td>Zurich, Switzerland</td></tr>
      </tbody>
    </Table>
    <SectionTitle>Features</SectionTitle>
    <Table>
      <tbody>
        <tr><td>2020</td><td><Link to="/picturesithink">Exhibition 'Pictures I Think' with Sebastian Stadler</Link> <span>at</span> <a href="https://www.kunstmuseumsg.ch/fileadmin/daten/downloads/stadler/Werkliste_Sebastian_Stadler.pdf" target="blank">Kunstmuseum St. Gallen, Switzerland</a></td></tr>
        <tr><td>2019</td><td><Link to="/roto">Roto Figma Plugin</Link> <span>on</span> <a href="https://www.figma.com/blog/introducing-figma-plugins/" target="blank">Figma Blog</a></td></tr>
        <tr><td>2011</td><td><Link to="/tmep">The Most Expensive Picture</Link> <span>on</span> <a href="https://www.itsnicethat.com/articles/the-most-expensive-project" target="blank">It's Nice That</a></td></tr>
      </tbody>
    </Table>
    <SectionTitle>Education</SectionTitle>
    <Table>
      <tbody>
        <tr><td>2005 – 2008</td><td>Design in Electronic Business <br/><span>Universität der Künste</span></td><td>Berlin, Germany</td></tr>
        <tr><td>2007 – 2008</td><td>Interaction Design Program <br/><span>Zürcher Hochschule der Künste</span></td><td>Zurich, Switzerland</td></tr>
        <tr><td>2002 – 2004</td><td>Business Informatics<br/><span>Universität Leipzig</span></td><td>Leipzig, Germany</td></tr>
      </tbody>
    </Table>
    <TextContent>
    <a href="https://www.linkedin.com/in/carlojoerges" target="blank">LinkedIn</a> · ‎<a href="https://twitter.com/carlojoerges" target="blank">Twitter</a> · ‎<a href="https://www.figma.com/@carlo/" target="blank">Figma</a> · ‎<a href="https://www.instagram.com/cjoerges/" target="blank">Instagram</a>
    </TextContent>
  </Layout>



export default AboutPage
